<template>
  <div>
    <a-menu
      v-model="cateCurrent"
      mode="horizontal"
      @click="menuChange"
      style="width: 100%; line-height: 54px; padding: 0 10px"
    >
      <a-menu-item :key="item.value" v-for="item in cateList">{{
        item.name
      }}</a-menu-item>
    </a-menu>
    <div class="home-page" style="padding: 20px">
      <div class="pd20 bg-w">
        <a-spin :spinning="loading">
          <div class="pd10">
            <a-list
              item-layout="vertical"
              size="large"
              :pagination="pagination"
              :data-source="listData"
            >
              <a-list-item slot="renderItem" key="item.title" slot-scope="item">
                <a-list-item-meta @click="see(item)">
                  <img
                    slot="avatar"
                    class="leftImg"
                    alt="logo"
                    :src="item.logo"
                  />
                  <div slot="title" class="item-tit text-over text-over1">
                    {{ item.title }}
                  </div>
                  <div
                    slot="description"
                    class="news-subtit text-over text-over3"
                  >
                    {{ item.description }}
                  </div>
                  <div slot="description" class="flex align-center mt24">
                    <div class="news-subtit" style="margin-top: 0">
                      2022-01-13 17:00
                    </div>
                    <div class="detail-btn">了解详情</div>
                  </div>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </div>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
const listData = [];
for (let i = 0; i < 23; i++) {
  listData.push({
    title: `标题标题标题标题标题标题标题标题标题标题标题标题 ${i}`,
    logo: "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png",
    description:
      "描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述",
  });
}

export default {
  components: {},
  data() {
    return {
      loading: false,
      cateCurrent: [1],
      cateList: [
        {
          name: "类别一",
          value: 1,
        },
        {
          name: "类别二",
          value: 2,
        },
        {
          name: "类别三",
          value: 3,
        },
      ],
      listData,
      pagination: {
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 5,
      },
    };
  },
  created() {
    //this.loaddata();
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("admin/home", {
          balance_time_type: this.search.balance_time_type,
          integral_time_type: this.search.integral_time_type,
        })
        .then((res) => {
          this.shop = res.shop;
          this.kefu = res.kefu;
          this.balance_recharge_data = res.balance_recharge_data;
          this.balance_consume_data = res.balance_consume_data;
          this.coupon_consume_data = res.coupon_consume_data;
          this.balance_rank_data = res.balance_rank_data;
          this.integral_rank_data = res.integral_rank_data;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    menuChange(e) {
      console.log(e);
    },
    see(record) {
      record.service_id = 1;
      this.$router.push("/service/detail?id=" + record.service_id);
    },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  min-width: 1000px;
  height: 94vh;
  overflow-y: auto;
}
.ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: #567af0;
  color: #567af0;
}
.ant-list-item-meta {
  max-width: 90%;
  align-items: center;
  .leftImg {
    width: 320px;
    height: 190px;
    border-radius: 4px;
    margin-right: 34px;
  }
  .item-tit {
    font-size: 16px;
    color: #3b426b;
    font-weight: 500;
  }
  .news-subtit {
    font-size: 12px;
    color: #7d88a2;
    margin-top: 14px;
  }
  .detail-btn {
    background: #3b426b;
    width: 86px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    margin-left: 40px;
    text-align: center;
  }
}
</style>
